<template>
	<div class="animated fadeIn">
		<b-card :title="title" sub-title="Summary overview of all assets within the supply chain">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-row class="mb-4">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Select Company" v-show="!this.isSuperAdmin">
							<v-select class="style-chooser" label="text" :options="allCompanyAccessOptions"
								:reduce="(company) => company.value" v-model="selCompanyAccess">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row no-gutters>
					<b-col lg="3" md="5" sm="12" class="mr-2">
						<b-card no-body class="bg-light">
							<b-card-body class="pb-0">
								<div class="row">
									<div class="col-md-5 pull-left">
										<h4>{{ allAssetMetrics.total }}</h4>
									</div>
									<div class="col-md-7 text-left">
										<span class="total-assets-tagged">
											Tagged:
											<strong>{{ taggedAssets }}</strong>
											<br />Untagged:
											<strong>{{ untaggedAssets }}</strong>
										</span>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<p class="stat-label">Total Assets</p>
									</div>
								</div>
							</b-card-body>
						</b-card>
					</b-col>
					<b-col lg="2" md="5" sm="12" class="mr-2">
						<b-card no-body class="bg-warning">
							<b-card-body class="pb-0">
								<h4 class="mb-3">{{ dispatchAssetMetrics }}</h4>
								<p class="stat-label">Dispatched</p>
							</b-card-body>
						</b-card>
					</b-col>
					<b-col lg="2" md="5" sm="12" class="mr-2">
						<b-card no-body class="bg-success">
							<b-card-body class="pb-0">
								<h4 class="mb-3">
									{{ inTransitAssetMetrics }}
								</h4>
								<p class="stat-label">In-Transit</p>
							</b-card-body>
						</b-card>
					</b-col>
					<b-col lg="2" md="5" sm="12" class="mr-2">
						<b-card no-body class="bg-primary">
							<b-card-body class="pb-0">
								<h4 class="mb-3">{{ receivedAssetMetrics }}</h4>
								<p class="stat-label">Received</p>
							</b-card-body>
						</b-card>
					</b-col>
					<b-col lg="2" md="5" sm="12" class="mr-2">
						<b-card no-body class="bg-danger">
							<b-card-body class="pb-0">
								<h4 class="mb-3">
									{{ lostAssetMetrics }}
								</h4>
								<p class="stat-label">Lost</p>
							</b-card-body>
						</b-card>
					</b-col>
				</b-row>

				<b-row>
					<b-col sm="6" md="5" class="mt-4 mb-2">
						<i class="icon-layers"></i>&nbsp;
						<b>ASSET TYPE DISTRIBUTION</b>
					</b-col>
					<b-col sm="6" md="4" offset-md="3" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>
				<b-table show-empty striped hover :items="dashboard.assetTypeDistributions" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
					<template v-slot:cell(totalQuantity)="row">
						<div class="clearfix">
							<div class="float-left">
								<strong>{{ row.item.totalQuantity }}</strong>
							</div>
						</div>
						<AssetTaggedStatusBar :tagged="row.item.tagged" :untagged="row.item.untagged"
							:total-quantity="row.item.totalQuantity" />
					</template>
					<template v-slot:cell(assetPoolPercentage)="row">
						<div class="clearfix">
							<div class="float-left">
								<strong>{{ row.item.assetPoolPercentage }}%</strong>
							</div>
							<div class="float-right">
								<small class="text-muted">{{ row.item.totalQuantity }} /
									{{ allAssetMetrics.total }}</small>
							</div>
						</div>
						<b-progress :value="(row.item.totalQuantity / allAssetMetrics.total) * 100"
							class="progress-xs" />
					</template>
				</b-table>
				<b-row class="mb-5">
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>

				<div v-if="!isLoading">
					<GoogleMap :assetLocationOverlayStr="assetLocationDistributions" :companyOptions="allCompanyOptions"
						:storageLocationOptions="allStorageLocationOptions"
						:assetTypeCategoryOptions="allAssetTypeCategoryOptions" />
				</div>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Component
import AssetTaggedStatusBar from '@/views/commons/dashboard/AssetTaggedStatusBar';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import dashboardApi from '@/api/dashboardApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'dashboard',
	components: {
		Loading,
		AssetTaggedStatusBar,
		GoogleMap: () => import('@/views/commons/GoogleMap'),
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'distributionArea',
					label: 'Distribution Area',
				},
				{
					key: 'assetType',
					sortable: true,
				},
				{
					key: 'totalQuantity',
					label: 'Total',
					sortable: true,
				},
				{
					key: 'assetPoolPercentage',
					label: 'Percentage',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			selCompanyAccess: null,

			allCompanyAccessOptions: [],
			allCompanyOptions: [],
			allStorageLocationOptions: [],
			allAssetTypeCategoryOptions: [],

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectedStorageLocationsObj: {},
			allAssetTypeCategoriesObj: {},

			dashboard: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Assets Summary';
		},
		allAssetMetrics() {
			return this.dashboard.assetMetrics && this.dashboard.assetMetrics.all
				? this.dashboard.assetMetrics.all
				: { total: 0 };
		},
		dispatchAssetMetrics() {
			return this.dashboard.assetMetrics &&
				this.dashboard.assetMetrics.dispatched
				? this.dashboard.assetMetrics.dispatched
				: 0;
		},
		receivedAssetMetrics() {
			return this.dashboard.assetMetrics && this.dashboard.assetMetrics.received
				? this.dashboard.assetMetrics.received
				: 0;
		},
		inTransitAssetMetrics() {
			return this.dashboard.assetMetrics &&
				this.dashboard.assetMetrics.inTransit
				? this.dashboard.assetMetrics.inTransit
				: 0;
		},
		lostAssetMetrics() {
			return this.dashboard.assetMetrics && this.dashboard.assetMetrics.lost
				? this.dashboard.assetMetrics.lost
				: 0;
		},
		assetLocationDistributions() {
			return this.dashboard && this.dashboard.assetLocationDistributions
				? JSON.stringify(this.dashboard.assetLocationDistributions)
				: '';
		},
		taggedAssets() {
			return this.allAssetMetrics.tagged;
		},
		untaggedAssets() {
			return this.allAssetMetrics.untagged;
		},
		taggedAssetsInPercentage() {
			const num =
				(this.allAssetMetrics.tagged / this.allAssetMetrics.total) * 100;
			return Math.round((num + Number.EPSILON) * 100) / 100 + '%';
		},
		untaggedAssetsInPercentage() {
			const num =
				(this.allAssetMetrics.untagged / this.allAssetMetrics.total) * 100;
			return Math.round((num + Number.EPSILON) * 100) / 100 + '%';
		},
	},
	watch: {
		selCompanyAccess(newVal, _oldVal) {
			this.onCompanyAccessChanged(newVal);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				// Company Filter Options
				this.allCompaniesObj = this.$store.getters.companies;
				this.allConnectedCompaniesObj = this.$store.getters.connectedCompanies;
				this.allCompaniesObj = Object.assign(
					{},
					this.allCompaniesObj,
					this.allConnectedCompaniesObj
				);
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);

				// Storage Location Filter Options
				this.allStorageLocationsObj = this.$store.getters.storageLocations;
				this.allConnectedStorageLocationsObj =
					this.$store.getters.connectedStorageLocations;
				this.allStorageLocationsObj = Object.assign(
					{},
					this.allStorageLocationsObj,
					this.allConnectedStorageLocationsObj
				);
				this.allStorageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationItems(
						this.allStorageLocationsObj
					);

				// Asset Type Categories Filter Options
				this.allAssetTypeCategoriesObj =
					this.$store.getters.assetTypeCategories;
				this.allAssetTypeCategoryOptions =
					DropDownItemsUtil.retrieveAssetTypeCategoryItems(
						this.allAssetTypeCategoriesObj
					);

				if (this.isSuperAdmin) {
					let { data } = await dashboardApi.fetchAdminDashboard(
						this.loggedUser.id
					);
					this.dashboard = data;
				} else {
					this.initCompanyAccess();

					let { data } = await dashboardApi.fetchCompanyDashboard(
						this.loggedUser.id,
						this.loggedUserCompany.id
					);
					this.dashboard = data;
				}
				this.totalRows = this.dashboard.assetTypeDistributions.length;
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		initCompanyAccess() {
			this.allCompanyAccessOptions = [];

			// Add Sub Companies of the Current Company
			if (
				this.loggedUser.type === config.managerRole ||
				this.loggedUser.type === config.superAdminRole
			) {
				let currCompanyId = this.loggedUserCompany.id;
				let companiesObj = _.filter(this.allCompaniesObj, (o) => {
					return o.id === currCompanyId || o.parentCompanyId === currCompanyId;
				});
				this.allCompanyAccessOptions =
					DropDownItemsUtil.retrieveAllCompanyAccess(companiesObj);
			}

			// Add Company Access Options
			if (this.loggedUser.companyAccess) {
				let companyAssetOptions = DropDownItemsUtil.retrieveAllCompanyAccess(
					this.loggedUser.companyAccess
				);

				let companyNames = _.map(this.allCompanyAccessOptions, 'text');
				_.forEach(companyAssetOptions, o => {
					if (!companyNames.includes(o.text)) {
						this.allCompanyAccessOptions.push(o);
					}
				});
			}

			this.selCompanyAccess = DropDownItemsUtil.getCompanyAccessItem(
				this.loggedUserCompany
			);
		},
		async onCompanyAccessChanged(company) {
			this.isLoading = true;
			let { data } = await dashboardApi.fetchCompanyDashboard(
				this.loggedUser.id,
				company.id
			);
			this.dashboard = data;
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.stat-label {
	font-size: 14px;
}

.total-assets-tagged {
	font-size: 12px;
}
</style>