import config from '@/config/env-constants';

// Util
import { SortUtil } from '@/utils/sortUtil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import _ from 'lodash';


function getCompanyItem(company) {
	return {
		id: company.id,
		name: company.name,
		isActive: company.isActive,
		type: company.type,
		brand: company.brand,
		contactNo: company.contactNo
	}
}

function getStorageLocationItem(loc) {
	return {
		id: loc.id,
		name: loc.name,
		geoaddress: LocationUtil.getGeoaddress(
			loc.geoaddress
		),
		companyId: loc.companyId
	}
}

function getTransportationItem(transportation) {
	return {
		id: transportation.plateNo,
		name: transportation.plateNo,
		company: transportation.company,
		companyId: transportation.companyId
	}
}

function getUserItem(user) {
	return {
		id: user.id,
		name: name,
		companyId: user.companyId,
		type: user.type
	}
}

function getCompanyAccessItem(company) {
	return {
		id: company.id,
		name: company.name,
		description: company.description
	}
}


export const DropDownItemsUtil = {

	// individual option item retrieval
	getCompanyItem,
	getStorageLocationItem,
	getTransportationItem,
	getUserItem,
	getCompanyAccessItem,

	retrieveIndustryItems: (industries, hasOthers) => {
		let industryItems = [];

		// init value
		industryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(industries, industry => {
			industryItems.push({
				value: industry.id,
				text: industry.name,
			});
		});

		if (hasOthers) {
			// Others
			industryItems.push({
				value: 'Others',
				text: ' - Others - ',
			});
		}

		return industryItems;
	},

	retrieveCompanyItems: (companies, currCompany) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		let parentCompanyId = currCompany ? currCompany.parentCompanyId : '';

		_.forEach(companies, company => {
			companyItems.push({
				value: {
					id: company.id,
					name: company.name,
					isActive: company.isActive,
					type: company.type,
					brand: company.brand,
					contactNo: company.contactNo
				},
				text: company.name
			});
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		return companyItems;
	},

	retrieveAllCompanyAccess: companies => {
		let companyItems = [];

		_.forEach(companies, company => {
			companyItems.push({
				value: {
					id: company.id,
					name: company.name,
					description: company.description
				},
				text: company.name + ' - ' + company.description
			});
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		return companyItems;
	},

	retrieveAllChildCompanies: (currCompany, companies) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if (currCompany.id == company.parentCompanyId) {
				companyItems.push({
					value: {
						id: company.id,
						name: company.name,
						isActive: company.isActive,
						type: company.type,
						brand: company.brand,
						contactNo: company.contactNo
					},
					text: company.name
				});
			}
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		return companyItems;
	},

	retrieveCompanyAssetOwnerItems: companies => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if ((company.type === 'Asset Service Provider' ||
				company.type === 'Hybrid') && company.brand !== '') {
				companyItems.push({
					value: getCompanyItem(company),
					text: company.name
				});
			}
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		return companyItems;
	},

	retrieveAllCompanyItems: (companies) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			companyItems.push({
				value: getCompanyItem(company),
				text: company.name
			});
		});

		return companyItems;
	},

	retrieveActiveCompanyItems: (companies) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if (company.isActive === 'true') {
				companyItems.push({
					value: {
						id: company.id,
						name: company.name,
						isActive: company.isActive,
						type: company.type,
						brand: company.brand,
						contactNo: company.contactNo
					},
					text: company.name
				});
			}
		});

		return companyItems;
	},

	retrieveInactiveCompanyItems: (companies) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(companies, company => {
			if (company.isActive !== 'true') {
				companyItems.push({
					value: {
						id: company.id,
						name: company.name,
						isActive: company.isActive,
						type: company.type,
						brand: company.brand,
						contactNo: company.contactNo
					},
					text: company.name
				});
			}
		});

		return companyItems;
	},

	// A company can only dispatch from the source location either from an inactive node
	// or the current company itself.
	retrieveSourceCompanyItems: (connections, currCompany) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		// include the company the current user belongs to
		let currCompanyOption = {
			value: {
				id: currCompany.id,
				name: currCompany.name,
				isActive: currCompany.isActive,
				type: currCompany.type,
				brand: currCompany.brand,
				contactNo: currCompany.contactNo
			},
			text: currCompany.name
		};
		companyItems.push(currCompanyOption);

		// include other connection companies
		_.forEach(connections, connection => {
			let conCompanyOption = {
				value: {
					id: connection.connectedCompanyId,
					isActive: connection.connectedCompany.isActive,
					name: connection.connectedCompany.name,
					type: connection.connectedCompany.type,
					brand: connection.connectedCompany.brand,
					contactNo: connection.connectedCompany.contactNo
				},
				text: connection.connectedCompany.name
			};

			if (connection.connectedCompany.isActive === 'false') {
				let companyNamesArr = _.map(companyItems, 'text');
				if (!_.includes(companyNamesArr, connection.connectedCompany.name)) {
					companyItems.push(conCompanyOption);
				}
			}
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		return companyItems;
	},

	retrieveAllConnectedCompanyItems: (connections) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		// include other connection companies
		_.forEach(connections, connection => {
			let conCompanyOption = {
				value: {
					id: connection.connectedCompanyId,
					isActive: connection.connectedCompany.isActive,
					name: connection.connectedCompany.name,
					type: connection.connectedCompany.type,
					brand: connection.connectedCompany.brand,
					contactNo: connection.connectedCompany.contactNo
				},
				text: connection.connectedCompany.name
			};

			let companyNamesArr = _.map(companyItems, 'text');
			if (!_.includes(companyNamesArr, connection.connectedCompany.name)) {
				companyItems.push(conCompanyOption);
			}
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		// remove duplicates
		companyItems = _.unionBy(companyItems, companyItems, 'text');

		return companyItems;
	},

	retrieveConnectedCompanyItems: (connections, currCompany) => {
		let companyItems = [];

		companyItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		// include the company the current user belongs to
		let currCompanyOption = {
			value: {
				id: currCompany.id,
				isActive: currCompany.isActive,
				name: currCompany.name,
				type: currCompany.type,
				brand: currCompany.brand,
				contactNo: currCompany.contactNo
			},
			text: currCompany.name
		};
		companyItems.push(currCompanyOption);

		// include other connection companies
		_.forEach(connections, connection => {
			if (connection.companyId === currCompany.id
				&& connection.isActive === 'true') {
				let conCompanyOption = {
					value: {
						id: connection.connectedCompanyId,
						isActive: connection.connectedCompany.isActive,
						name: connection.connectedCompany.name,
						type: connection.connectedCompany.type,
						brand: connection.connectedCompany.brand,
						contactNo: connection.connectedCompany.contactNo
					},
					text: connection.connectedCompany.name
				};

				let companyNamesArr = _.map(companyItems, 'text');
				if (!_.includes(companyNamesArr, connection.connectedCompany.name)) {
					companyItems.push(conCompanyOption);
				}
			}
		});

		// sort company option selection
		companyItems = SortUtil.sortByAttr('text', companyItems);

		// remove duplicates
		companyItems = _.unionBy(companyItems, companyItems, 'text');

		return companyItems;
	},

	retrieveConnectionItems: connections => {
		let connectionItems = [];

		connectionItems.push({
			value: config.companyDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(connections, connection => {
			connectionItems.push({
				value: {
					companyId: connection.companyId,
					id: connection.connectedCompany.id,
					isActive: connection.connectedCompany.isActive,
					name: connection.connectedCompany.name
				},
				text: connection.connectedCompany.name
			});
		});

		// sort dropdown items
		connectionItems = SortUtil.sortByAttr('text', connectionItems);

		return connectionItems;
	},

	retrieveStorageLocationItems: storageLocations => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			storageLocationItems.push({
				value: {
					id: loc.id,
					name: loc.name,
					geoaddress: LocationUtil.getGeoaddress(
						loc.geoaddress
					),
					companyId: loc.companyId
				},
				text: loc.name
			});
		});

		return storageLocationItems;
	},

	retrieveActiveStorageLocationItems: storageLocations => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			if (loc.isActive === 'true') {
				storageLocationItems.push({
					value: {
						id: loc.id,
						name: loc.name,
						geoaddress: LocationUtil.getGeoaddress(
							loc.geoaddress
						),
						companyId: loc.companyId
					},
					text: loc.name
				});
			}
		});

		return storageLocationItems;
	},

	retrieveConnectedStorageLocationItems: (connections, currStorageLocations) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		// 1.1 add all storage locations from all connections
		_.forEach(connections, connection => {
			_.forEach(connection.storageLocations, loc => {
				if (loc.isIncluded === 'true') {
					storageLocationItems.push({
						value: {
							id: loc.id,
							name: loc.name,
							geoaddress: LocationUtil.getGeoaddress(
								loc.geoaddress
							),
							companyId: connection.connectedCompanyId
						},
						text: loc.name
					});
				}
			});
		});

		// 1.2 add all storage locations from storage locations of the current company
		_.forEach(currStorageLocations, loc => {
			storageLocationItems.push({
				value: getStorageLocationItem(loc),
				text: loc.name
			});
		});

		// sort storage location option selection
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);

		// remove duplicates
		storageLocationItems = _.unionBy(storageLocationItems, storageLocationItems, 'text');

		return storageLocationItems;
	},

	retrieveStorageLocationOfConnectionItems: (connections, storageLocations, companyId, connectedCompanyId) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});


		if (companyId === connectedCompanyId) {
			// retrieve from list of storage locations
			_.forEach(storageLocations, loc => {
				if (loc.companyId === companyId && loc.isActive === 'true') {
					storageLocationItems.push({
						value: getStorageLocationItem(loc),
						text: loc.name
					});
				}
			});

		} else {
			// retrieve from list of connections
			_.forEach(connections, connection => {
				if (connection.companyId === companyId
					&& connection.connectedCompanyId === connectedCompanyId
					&& connection.isActive === 'true') {
					let storageLocationsArr = Object.values(connection.storageLocations);
					storageLocationsArr.forEach(loc => {
						if (loc.isIncluded === 'true') {
							storageLocationItems.push({
								value: {
									id: loc.id,
									name: loc.name,
									geoaddress: LocationUtil.getGeoaddress(
										loc.geoaddress
									),
									companyId: connection.connectedCompanyId
								},
								text: loc.name
							});
						}
					});
				}
			});
		}

		// sort storage location option selection
		storageLocationItems = SortUtil.sortByAttr('text', storageLocationItems);

		return storageLocationItems;
	},

	retrieveStorageLocationOfCurrCompany: (currCompanyId, storageLocations) => {
		let storageLocationItems = [];

		storageLocationItems.push({
			value: config.storageLocationDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(storageLocations, loc => {
			if (loc.companyId === currCompanyId && loc.isActive === 'true') {
				storageLocationItems.push({
					value: {
						id: loc.id,
						name: loc.name,
						geoaddress: LocationUtil.getGeoaddress(
							loc.geoaddress
						),
						companyId: loc.companyId
					},
					text: loc.name
				});
			}
		});

		return storageLocationItems;
	},

	retrieveAssetTypeItems: assetTypes => {
		let assetTypeItems = [];
		assetTypeItems.push({
			value: config.assetTypeDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(assetTypes, type => {
			assetTypeItems.push({
				value: {
					id: type.id,
					name: type.name,
					origin: type.origin,
					originId: type.originId
				},
				text: type.name
			});
		});

		return assetTypeItems;
	},

	retrieveAssetTypeCategoryItems: assetTypeCategories => {
		let assetTypeCategoryItems = [];

		assetTypeCategoryItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(assetTypeCategories, category => {
			assetTypeCategoryItems.push({
				value: category.name,
				text: category.name
			});
		});

		return assetTypeCategoryItems;
	},

	retrieveAssetTypeCodeItems: assetTypeCodes => {
		let assetTypeCodeItems = [];

		assetTypeCodeItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(assetTypeCodes, code => {
			assetTypeCodeItems.push({
				value: {
					id: code.id,
					codeName: code.codeName,
					prefix: code.prefix
				},
				text: code.codeName
			});
		});

		return assetTypeCodeItems;
	},

	retrieveActiveUserItems: users => {
		let userItems = [];

		userItems.push({
			value: config.userDefaultValue,
			text: ' - Please select - '
		});

		_.forEach(users, user => {
			if (user.isActive === 'true') {
				let name = user.firstName + ' ' + user.lastName;
				userItems.push({
					value: {
						id: user.id,
						name: name,
						companyId: user.companyId,
						type: user.type
					},
					text: name
				});
			}
		});

		return userItems;
	},

	retrieveTranportationItems: transportations => {
		let transportationItems = [];

		transportationItems.push({
			value: config.transportationDefaultValue,
			text: ' - Please select - '
		});

		transportationItems.push({
			value: config.transportationOthersValue,
			text: ' - Others - '
		});

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				transportationItems.push({
					value: {
						id: transportation.plateNo,
						name: transportation.plateNo,
						company: transportation.company,
						companyId: transportation.companyId
					},
					text: transportation.plateNo
				});
			}
		});

		return transportationItems;
	},

	retrievePlateNoItems: transportations => {
		let plateNoItems = [];

		plateNoItems.push({
			value: '',
			text: ' - Please select - '
		});

		_.forEach(transportations, transportation => {
			if (transportation.isActive === 'true') {
				plateNoItems.push({
					value: transportation.plateNo,
					text: transportation.plateNo
				});
			}
		});

		return plateNoItems;
	},

	retriveAssetTaggingItems: () => {
		return [
			{
				value: '',
				text: ' - Please select - '
			},
			{ text: 'Default', value: "Default" },
			{ text: 'Custom', value: "Custom" },
		];
	},

	retrieveAssetCategoryItems: (categories) => {
		let assetCategoryItems = [];

		assetCategoryItems.push({
			value: null,
			text: ' - Please select - ',
		});

		_.forEach(categories, category => {
			assetCategoryItems.push({
				value: category.name,
				text: category.name,
			});
		});

		return assetCategoryItems;
	},

	retriveUOMItems: (uoms) => {
		let uomItems = [];

		uomItems.push({
			value: null,
			text: ' - Please select - '
		});

		_.forEach(uoms, uom => {
			uomItems.push({
				value: uom.id,
				text: uom.code + ' (' + uom.name + ')',
			});
		});

		return uomItems;
	}
};
