import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    fetchAdminDashboard(currUserId) {
        let url = `${this.baseUrl}/adminDashboard`;
        return axios.post(url, { currUserId: currUserId });
    },

    fetchCompanyDashboard(currUserId, companyId) {
        let url = `${this.baseUrl}/companyDashboard`;
        return axios.post(url, { currUserId: currUserId, companyId: companyId });
    }
}