<template>
	<b-progress :max="totalQuantity" height="5px" :style="{ cursor: 'pointer' }">
		<b-progress-bar
			variant="danger"
			:value="untagged"
			v-b-tooltip.hover
			:title="`Untagged: ${untaggedAssetsPercentage}%`"
		/>

		<b-progress-bar
			variant="primary"
			:value="tagged"
			v-b-tooltip.hover
			:title="`Tagged: ${taggedAssetsPercentage}%`"
		/>
	</b-progress>
</template>

<script>
export default {
	name: 'AssetTaggedStatusBar',
	props: {
		tagged: {
			type: Number,
			required: true,
		},
		untagged: {
			type: Number,
			required: true,
		},
		totalQuantity: {
			type: Number,
			required: true,
		},
	},
	computed: {
		taggedAssetsPercentage() {
			return parseFloat(((this.tagged / this.totalQuantity) * 100).toFixed(2));
		},
		untaggedAssetsPercentage() {
			return parseFloat(
				((this.untagged / this.totalQuantity) * 100).toFixed(2)
			);
		},
	},
};
</script>